import * as React from 'react';
import './Footer.css'
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography, Link } from '@mui/material';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import GoogleIcon from '@mui/icons-material/Google';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {

    return (

        <React.Fragment>
            <CssBaseline />
            <div className='footer'>
                <Container maxWidth="sm">
                    <Box paddingTop={3}>
                        <Typography variant='subtitle1' align='center' color='text.secondary' gutterBottom>
                            Need Support? Contact <Link onClick={() => window.location = 'mailto:digicatmain@gmail.com'} underline="none" style={{cursor: 'pointer'}}>digicatmain@gmail.com</Link>
                        </Typography>
                    </Box>
                    {/* <Box marginTop={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <LinkedInIcon sx={{ margin: 2 }} />
                        <FacebookIcon sx={{ margin: 2 }} />
                        <TwitterIcon sx={{ margin: 2 }} />
                        <GoogleIcon sx={{ margin: 2 }} />
                        <InstagramIcon sx={{ margin: 2 }} />
                    </Box> */}
                </Container>
                <div className='copyright'>
                    <Container maxWidth="sm">
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            © 2022 All right reserved by <Link href="https://adeonatech.net/" underline="none" paddingLeft={1}>Adeona Technologies</Link>
                        </Box>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Footer;