import '../queue-card/QueueCard.css';
import * as React from 'react';
import { config } from '../../Constants'
import { useState } from 'react'
import { Button, Typography, Skeleton, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { lightBlue } from '@mui/material/colors';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

function LiveCardSlider(props) {
    const { loading = false } = props;

    const [loadingImg, setLoadingImg] = useState(true);
    const [visible, setVisible] = useState(false);
    const imageLoaded = () => {
        setLoadingImg(false);
    }

    return (
        <Card sx={{ maxWidth: 300 }} className="card">
            {visible ? (<Lightbox image={config.url.IMG_URL + props.slide.imagePath} title={props.slide.productName} onClose={() => {
                setVisible(false);
            }}></Lightbox>) : null}
            <CardHeader
                avatar={
                    loading ? (
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    ) : (<Avatar sx={{ bgcolor: lightBlue[500] }} aria-label="recipe">
                        <Typography variant="caption">Next</Typography>
                    </Avatar>)
                }
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="40%" />
                    ) : (<div className='card-subheader'><Typography className='card-subheader-expire' display="inline">Last bid - </Typography><Typography className='card-subheader-date' display="inline">{props.slide.endTime}</Typography></div>)}
                className="card-header"
            ></CardHeader>
            {loadingImg ? (
                <div>
                    <img src={config.url.IMG_URL + props.slide.imagePath} onLoad={imageLoaded} alt=""/>
                    <Skeleton sx={{ height: 425, width: 300 }} animation="wave" variant="rectangular" />
                    </div>
            ) : (<CardMedia
                sx={{ height: 425, width: 300, cursor: 'pointer' }}
                component="img"
                image={config.url.IMG_URL+props.slide.imagePath}
                alt="green iguana"
                onClick={() => {
                    setVisible(true);
                }}
            />)}
            <CardContent style={{width: "auto"}}>
            <Box
            fontSize="h5.fontSize"
            component="div" 
            overflow="hidden"            
            whiteSpace="pre-line"
            textOverflow="ellipsis"  
            textAlign="center"
            fontWeight="bold"
            color="rgba(255, 255, 255, 0.75)"
            height={80}
          >
            {props.slide.productName}
          </Box>
                
                {loading ? (
                    <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                ) : (<Box
                    fontSize="body2.fontSize"
                    component="div" 
                    overflow="hidden"            
                    whiteSpace="pre-line"
                    textOverflow="ellipsis"
                    color="rgba(255, 255, 255, 0.75)"
                    height={50}
                  >
                    Winning Bid: <Typography variant='body1'> Rs: {props.slide.winningBid}</Typography>
                </Box>)}
            </CardContent>
            <CardActions>
                {loading ? (
                    <Skeleton sx={{ height: 30, width: 60 }} animation="wave" variant="rectangular" />
                ) : (<Button size="small" color='primary'>Share</Button>)}
            </CardActions>
        </Card>
    );
}

export default LiveCardSlider;