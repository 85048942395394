import './LiveCard.css';
import * as React from 'react';
import { config } from '../../Constants'
import { useState } from 'react'
import { Button, Typography, Link, Skeleton, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import Marquee from "react-fast-marquee";
import TimerComponent from '../countdown/countdown'
import { format } from "date-fns";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

function LiveCardSlider(props) {
    const { loading = false } = props;

    const [loadingImg, setLoadingImg] = useState(true);
    const [visible, setVisible] = useState(false);
    const imageLoaded = () => {
        setLoadingImg(false);
    }

    function formatTimeComponent(time) {
        var date = new Date(time.replace(/ /g, "T"));
        var formattedDate = format(date, "MMM d, yyyy HH:mm:ss");
        return (<TimerComponent deadline={formattedDate}></TimerComponent>)
    }

    return (
        <Card sx={{ maxWidth: 300 }} className="card">
            {visible ? (<Lightbox image={config.url.IMG_URL + props.slide.imagePath} title={props.slide.productName} onClose={() => {
                setVisible(false);
            }}></Lightbox>) : null}
            <CardHeader
                avatar={
                    loading ? (
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    ) : (<Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                        <Typography variant="body1">Live</Typography>
                    </Avatar>)
                }
                title={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                    ) :
                        (props.slide.productName.length < 17 ? (<Typography variant="h6" className='card-header'>{props.slide.productName}</Typography>) : (<Marquee pauseOnHover={true} gradient={false}><Typography variant="h6" className='card-header'>{props.slide.productName}</Typography></Marquee>))}
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="40%" />
                    ) : (<div className='card-subheader'><Typography className='card-subheader-expire' display="inline">Expire On - </Typography><Typography className='card-subheader-date' display="inline">{props.slide.endTime}</Typography></div>)}
                className="card-header"
            ></CardHeader>
            {formatTimeComponent(props.slide.endTime)}
            {loadingImg ? (
                <div>
                    <img src={config.url.IMG_URL + props.slide.imagePath} onLoad={imageLoaded} alt=""/>
                    <Skeleton sx={{ height: 425, width: 300, bgcolor: 'grey.700' }} animation="wave" variant="rectangular" />
                </div>
            ) : (<CardMedia
                sx={{ height: 425, width: 300, cursor: 'pointer' }}
                component="img"
                image={config.url.IMG_URL + props.slide.imagePath}
                alt="green iguana"
                onClick={() => {
                    setVisible(true);
                }}
            />)}
            <CardContent>
                {/* <Typography gutterBottom variant="p" color="rgba(255, 255, 255, 0.75)">
                        Lizard
                      </Typography> */}
                {loading ? (
                    <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                ) : (<Box
                    fontSize="body2.fontSize"
                    component="div"
                    overflow="hidden"
                    whiteSpace="pre-line"
                    textOverflow="ellipsis"
                    color="rgba(255, 255, 255, 0.75)"
                    height={30}
                >
                    Dial <Link href="tel:#4545*2#" underline="hover">#4545*2#</Link> and place your bid
                </Box>)}
            </CardContent>
            <CardActions>
                {loading ? (
                    <Skeleton sx={{ height: 30, width: 60 }} animation="wave" variant="rectangular" />
                ) : (<Button size="small" color='primary'>Share</Button>)}
            </CardActions>
        </Card>
    );
}

export default LiveCardSlider;