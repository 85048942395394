import React, { Component } from 'react'
import './countdown.css'

class CountDown extends Component {
    constructor(props) {
        super(props)
        this.count = this.count.bind(this)
        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            time_up: false
        }
        this.x = null
        this.deadline = null
    }
    count() {
        var now = new Date().getTime();
        var t = this.deadline - now;
        var days = Math.floor(t / (1000 * 60 * 60 * 24)).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        var seconds = Math.floor((t % (1000 * 60)) / 1000).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        this.setState({ days, minutes, hours, seconds })
        if (t < 0) {
            clearInterval(this.x);
            this.setState({ days: '00', minutes: '00', hours: '00', seconds: '00', time_up: true })
        }
    }
    componentDidMount() {
        this.deadline = new Date(this.props.deadline).getTime();

        this.x = setInterval(this.count, 1000);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {
        const { days, seconds, hours, minutes, time_up } = this.state
        return (
            <div>
                <div id="clockdiv">
                    <div>
                        <span className="days" id="day">{days}</span>
                        <div className="smalltext">Days</div>

                    </div>
                    <div>
                        <span className="hours" id="hour">{hours}</span>
                        <div className="smalltext">Hours</div>

                    </div>
                    <div>
                        <span className="minutes" id="minute">{minutes}</span>
                        <div className="smalltext">Minutes</div>

                    </div>
                    <div>
                        <span className="seconds" id="second">{seconds}</span>
                        <div className="smalltext">Seconds</div>

                    </div>
                </div>

                <p id="demo">{time_up}</p>
            </div>
        )
    }
}

export default CountDown