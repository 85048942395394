import * as React from 'react';
import { config } from '../../Constants'
import { useState, useEffect } from 'react'
import { useMediaQuery } from "react-responsive";
import { Container, Typography, Badge, } from '@mui/material';
import CardSlider from '../card-slider/CardSlider';
import QueueCardSlider from '../queue-card-slider/QueueCardSlider'
import PastCardSlider from '../past-card-slider/PastCardSlider'
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

function Home(props) {

  const isMobile = useMediaQuery({

    query: "(max-width: 786px)"

  });

  const [counts, setCounts] = useState(null);

  useEffect(() => {
    var baseUrl = config.url.API_URL

    fetch(baseUrl + '/mybids/api/v1/products/getCountsWiseBiddingStatus')
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        setCounts(data.data);

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

      })
      .catch(error => {
        console.error('There was an error!', error);
      });


    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>
            <Badge badgeContent={counts ? counts.live : 0} color="success">
              {isMobile ? (<Typography variant='body1' fontWeight={'bold'} style={{ maxWidth: '6rem', wordWrap: 'break-word' }}>Live Products</Typography>) : (<Typography variant='body1' fontWeight={'bold'}>Live Products</Typography>)}
            </Badge>
          </Tab>
          <Tab>
            <Badge badgeContent={counts ? counts.queue : 0} color="primary">
              {isMobile ? (<Typography variant='body1' fontWeight={'bold'} style={{ maxWidth: '6rem', wordWrap: 'break-word' }}>Next Products</Typography>) : (<Typography variant='body1' fontWeight={'bold'}>Next Products</Typography>)}
            </Badge>
          </Tab>
          <Tab>
            <Badge badgeContent={counts ? counts.completed : 0} color="warning">
              {isMobile ? (<Typography variant='body1' fontWeight={'bold'} style={{ maxWidth: '6rem', wordWrap: 'break-word' }}>Past Products</Typography>) : (<Typography variant='body1' fontWeight={'bold'}>Past Products</Typography>)}
            </Badge>
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <div>
            <Container maxWidth='sm'>
              <Typography marginTop={2} variant='h4' align='center' color='text.secondary' gutterBottom>
                Live Products Auction
              </Typography>
              {props.msisdn ?
                props.customer ?
                  props.customer.subscriptionStatus === 2 ?
                    (<Typography marginTop={1} variant='inherit' align='center' color='text.secondary' paragraph>
                      Please subscribe...
                    </Typography>) :
                    (null) :
                  (<Typography marginTop={1} variant='inherit' align='center' color='text.secondary' paragraph>
                    Please subscribe...
                  </Typography>) :
                (<Typography marginTop={1} variant='inherit' align='center' color='text.secondary' paragraph>
                  Please use Dialog Mobile data to continue...
                </Typography>)}
            </Container>
          </div>
          <div style={{ marginTop: 50 }}>
            <Container maxWidth='xl'>
              <CardSlider />
            </Container>
          </div>
        </TabPanel>
        <TabPanel value={1}>
          <div>
            <Container maxWidth='sm'>
              <Typography marginTop={4} variant='h4' align='center' color='text.secondary' gutterBottom>
                Next Products Auction
              </Typography>
            </Container>
          </div>
          <div style={{ marginTop: 50 }}>
            <Container maxWidth='xl'>
              <QueueCardSlider />
            </Container>
          </div>
        </TabPanel>
        <TabPanel value={2}>
          <div>
            <Container maxWidth='sm'>
              <Typography marginTop={4} variant='h4' align='center' color='text.secondary' gutterBottom>
                Past Products Auction
              </Typography>
            </Container>
          </div>
          <div style={{ marginTop: 50 }}>
            <Container maxWidth='xl'>
              <PastCardSlider />
            </Container>
          </div>
        </TabPanel>
      </TabsUnstyled>
    </div>
  );
}

export default Home;