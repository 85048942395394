import React, { useState, useEffect } from 'react';
import './PastCardSlider.css';
import { config } from '../../Constants'
import PastCard from '../past-card/PastCard';
import { Grid, Box, CircularProgress } from '@mui/material';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';
import PropTypes from 'prop-types';

function CardSlider() {

    const [postData, setPostData] = useState(null);

    useEffect(() => {
        var baseUrl = config.url.API_URL
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ biddingState: 'completed' })
        };
        fetch(baseUrl+'/mybids/api/v1/products/getProducts', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                setPostData(data.data);

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    const slideLeft = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 500;
    }

    const slideRight = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft - 500;
    }

    return (
        postData ? <div id='main-slider-container'>
            <MdOutlineChevronLeft className='slider-icon left' size={40} onClick={slideLeft}></MdOutlineChevronLeft>
            <div id='slider'>
                {postData.map((slide, index) => (
                    <Grid item key={index} className='card-grid'>
                        <PastCard slide={slide} />
                    </Grid>
                ))}
            </div>
            <MdOutlineChevronRight className='slider-icon right' size={40} onClick={slideRight}></MdOutlineChevronRight>
        </div> :
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={90} />
            </Box>
    );
}

PastCard.propTypes = {
    loading: PropTypes.bool,
};

export default CardSlider;