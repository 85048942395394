import './App.css';
import logo from './mybid_logo_white.png';
import { config } from './Constants'
import * as React from 'react';
import { useState, useEffect } from 'react'
import { useMediaQuery } from "react-responsive";
import { AppBar, Button, CssBaseline, Toolbar, Typography, Tooltip, IconButton, Avatar, Menu, MenuItem, Divider, Container, Link, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { red, green, orange } from '@mui/material/colors';
import theme from './theme/common-styles';
import Footer from './components/footer/Footer'
import Home from './components/Home/Home'
import BidHistory from './components/bid-history/BidHistory'
import ChargeHistory from './components/charge-history/ChargeHistory'
import TutorialEnglish from './components/tutorial/TutorialEnglish'
import TutorialSinhala from './components/tutorial/TutorialSinhala'
import TutorialTamil from './components/tutorial/TutorialTamil'

function App() {

  const isMobile = useMediaQuery({

    query: "(max-width: 786px)"

  });

  const [state, setState] = useState('english');
  const [customer, setCustomer] = useState(null);
  const [msisdn, setMsisdn] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function setSubscriptionStatus(status) {
    switch (status) {
      case 1:
        return { name: 'Subscribed', color: green[500] };
      case 2:
        return { name: 'UnSubscribed', color: red[500] };
      case 3:
        return { name: 'Charging Failed', color: orange[500] };
      default:
        return { name: 'invalid', color: null };
    }
  };

  useEffect(() => {
    var baseUrl = config.url.API_URL

    fetch(baseUrl + '/mybids/api/v1/customer/get')
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        setCustomer(data.data);
        setMsisdn(data.msisdn);

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

      })
      .catch(error => {
        console.error('There was an error!', error);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position='relative'>
        <Toolbar>
          {isMobile ? (<Box sx={{ flexGrow: 1 }}>
            <img src={logo} alt="Logo" className='logo-mobile' />
            <Typography variant='h6' component="div">
              <Link href="/" underline="none" color="inherit">MyBid.lk</Link>
            </Typography>
          </Box>) : (<><img src={logo} alt="Logo" className='logo-desktop' />
            <Typography variant='h6' component="div" sx={{ flexGrow: 1 }}>
              <Link href="/" underline="none" color="inherit">MyBid.lk</Link>
            </Typography></>)}
          <Button variant='outlined' sx={{ mr: 1 }} onClick={() => setState('english')} className={state === 'english' ? "active-language-btn" : "language-btn"}><Typography variant="caption">EN</Typography></Button>
          <Button variant='outlined' sx={{ mr: 1 }} onClick={() => setState('sinhala')} className={state === 'sinhala' ? "active-language-btn" : "language-btn"}><Typography variant="caption">සිං</Typography></Button>
          <Button variant='outlined' sx={{ mr: 1 }} onClick={() => setState('tamil')} className={state === 'tamil' ? "active-language-btn" : "language-btn"}><Typography variant="caption">தமிழ்</Typography></Button>
          {msisdn ? (<div><Tooltip title={'+94' + msisdn}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
            </IconButton>
          </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <Avatar /> {'+94' + msisdn}
              </MenuItem>
              <MenuItem>
                {customer ?
                  (<Typography variant='h6' sx={{ color: setSubscriptionStatus(customer.subscriptionStatus).color }}>
                    {setSubscriptionStatus(customer.subscriptionStatus).name}
                  </Typography>) :
                  (<Typography variant='h6' sx={{ color: red[500] }}>
                    Unsubscribed
                  </Typography>)}
              </MenuItem>
              <Divider />
              <MenuItem component='a' href='/bid-history'>
                Bid History
              </MenuItem>
              <MenuItem component='a' href='/charge-history'>
                Charging History
              </MenuItem>
              {/* <MenuItem>
              My Wins
            </MenuItem> */}
            </Menu></div>) : (null)}
        </Toolbar>
      </AppBar>
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<Home customer={customer} msisdn={msisdn} />} exact />
            <Route path="/bid-history" element={<BidHistory />} exact />
            <Route path="/charge-history" element={<ChargeHistory />} exact />
          </Routes>
        </Router>
        <Container>

          {state === 'english' && <TutorialEnglish />}
          {state === 'sinhala' && <TutorialSinhala />}
          {state === 'tamil' && <TutorialTamil />}
        </Container>
      </main>
      <Footer />
    </ThemeProvider >
  );
}

export default App;
