import * as React from 'react';
import { Typography, Link, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

function TutorialEnglish() {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Typography marginTop={3} variant='body1' fontWeight={'bold'}>Mybids යනු කුමක්ද?</Typography>
            <Typography marginBottom={1} variant='body2'>MyBids යනු ලංසු තැබීමේ වේදිකාවක් වන අතර ඔබේ සිහින නිෂ්පාදනය වෙළඳපල මිලෙන් සුළු මුදලකට මිලදී ගැනීමට ඔබට හැකියාව ලැබේ.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>එය ක‍්‍රියා කරන්නේ කෙසේද?</Typography>
            <Typography marginBottom={1} variant='body2'>දායකත්වය මත ඔබට දිනකට ලංසු තැබීමේ අවස්ථා 5ක් ලැබෙනු ඇත. Mybids පද්ධතිය හරහා ලංසු තැබීමෙන් පසු ජයග්‍රාහකයා ලෙස අඩුම සහ අද්විතීය ලංසු තැබූ පාරිභෝගිකයා තෝරා ගනු ඇත.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>සක‍්‍රිය කරන්නේ කෙසේද?</Typography>
            <Typography marginBottom={1} variant='body2'><Link href="tel:#4545*1#" underline="hover">#4545*1#</Link> අමතන්න</Typography>
            <Typography variant='body1' fontWeight={'bold'}>අක‍්‍රිය කරන්නේ කෙසේද?</Typography>
            <Typography marginBottom={1} variant='body2'><Link href="tel:#4545*5#" underline="hover">#4545*5#</Link> අමතන්න</Typography>
            <Typography variant='body1' fontWeight={'bold'}>ක‍්‍රීඩා කරන්නේ කෙසේද?</Typography>
            <Typography marginBottom={1} variant='body2'><Link href="tel:#4545*2#" underline="hover">#4545*2#</Link> අමතා ඔබේ ලංසුව තබන්න. නැතහොත් "BID your bid value" (උදා: BID 40) ටයිප් කර 4545 ට SMS කරන්න.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>දායකත්වය සඳහා කොපමණ මුදලක් වැය වේද?</Typography>
            <Typography marginBottom={3} variant='body1'>දිනකට රු.6 + බදු පමණයි..</Typography>
            <Typography marginBottom={10}>
                <Button variant='contained' color='primary' onClick={handleClickOpen('paper')}>
                    නියම සහ කොන්දේසි
                </Button></Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" fontWeight='bold'>Dialog MyBid සේවාවේ නියමයන් සහ කොන්දේසි පහත දැක්වේ</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <ol>
                        <li>තරඟයේ ස්වභාවය</li>
                        <ul>
                            <li>මෙම තරඟය ත්‍යාග දිනාගැනීමේ විශාල අභිප්‍රායක් ඇති Dialog පාරිභෝගිකයින් සඳහා ඉලක්ක කර ඇත.</li>
                            <li>මෙම තරඟය ග්‍රාහකයින්ට ලංසු තැබීමේ අත්දැකීමකට සහභාගී වී වටිනා ත්‍යාග දිනා ගැනීමට අවස්ථාවක් ලබා දෙයි.</li>
                            <li>අඩුම සහ අද්විතීය ලංසුව මත පදනම්ව ජයග්‍රාහකයා තෝරාගනු ලැබේ.(තරඟයේ සාමාන්‍ය හැසිරීම)</li>
                            <li>තරඟයට සහභාගී වීමෙන්, සෑම ග්‍රාහකයෙකුම/පාරිභෝගිකයෙකුම මෙහි ඇති නියමයන් සහ කොන්දේසි වලට කොන්දේසි විරහිතව එකඟ විය යුතු අතර, සියලුම කාරණා සම්බන්ධයෙන් Dialog Axita PLC (MyBids.lk) හි තීරණයට/බැඳීමට එකඟ විය යුතුය.</li>
                            <li>ත්‍යාග තරඟයට සහභාගී වීමෙන් ග්‍රාහකයා ක්‍රීඩාවේ සියලුම නියමයන් සහ කොන්දේසි පිළිගැනීමට එකඟ වන අතර Dialog හෝ MyBids.lk විසින් ලබා ගන්නා තීරණ අවසාන වශයෙන් පිළිගැනීමට එකඟ වන අතර ත්‍යාග තරඟයේ පැවැත්ම තුළ සහ ඉන් පසුව එය මතභේදයට තුඩු නොදෙනු ඇත.</li>
                        </ul>
                        <li>පිවිසුම් අවශ්‍යතා</li>
                        <ul>
                            <li>පාරිභෝගිකයින්ට වඩාත් කැමති මාධ්‍යයක් හරහා සේවාවට ලියාපදිංචි විය හැක. (WEB, USSD, SMS)</li>
                        </ul>
                        <li>තරඟයේ කොන්දේසි</li>
                        <ul>
                            <li>එක් පුද්ගලයෙකුට දිනිය හැක්කේ එක් වරක් පමණි. පෙර ජයග්‍රාහී අංක හිමි ජාතික හැඳුනුම්පත් හිමියන්ට මීළඟ ත්‍යාගය ලබා නොදේ.</li>
                            <li>එකම ජාතික හැඳුනුම්පත් අංකයට ලියාපදිංචි වූ MSISDN/ජංගම අංක කිහිපයක් හරහා තරඟකරුවන්ට තරඟ කළ හැකි නමුත් ඔහු/ඇය තරඟයෙන් ජයග්‍රහණය කළ පසු ඊළඟ වතාවේ එම ජාතික හැඳුනුම්පත් දරන්නාට ඔහු/ඇය අඩුම ලංසුකරු වුවද ත්‍යාගය නිකුත් නොකෙරේ.</li>
                            <li>එකම ප්‍රාදේශීය ලිපිනය සඳහා ලියාපදිංචි MSISDN'S හරහා ත්‍යාගය දිනා ගත හැක්කේ එක් පුද්ගලයෙකුට පමණි.</li>
                            <li>ජයග්‍රාහී ජංගම දුරකථන අංකයේ මුල් හිමිකරු වලංගු ජාතික හැඳුනුම්පත හෝ හඳුනාගැනීමේ සාක්ෂි සමඟ ත්‍යාගය භාර ගැනීමට පැමිණිය යුතුය. එසේ නොමැතිනම් ජයග්‍රහණය ප්‍රතික්ෂේප කරනු ලබන අතර ඔහු/ඇය මීළඟ ලංසු වට සඳහා නුසුදුස්සකු වනු ඇත.</li>
                        </ul>
                        <li>නුසුදුසුකම සඳහා හේතු</li>
                        <ul>
                            <li>Dialog හෝ MyBids.lk හි කළමනාකාරීත්වයට ඕනෑම පුද්ගලයෙකු වෙනත් තරඟකරුවන් වංචා කිරීමට සහ වංචා කිරීමට උත්සාහ කරන බව තේරුම් ගනී, තරඟය - ඔහු/ඇය තරඟය සඳහා නුසුදුස්සකු වනු ඇත.</li>
                        </ul>
                        <li>පාරිභෝගික විමසීම් සහ පැමිණිලි</li>
                        <ul>
                            <li>ත්‍යාග තරඟයට අදාළ සාමාන්‍ය පැමිණිලි සහ විමසීම් සඳහා සියලුම ග්‍රාහකයින්ට Dialog පාරිභෝගික සේවා ක්ෂණික ඇමතුම් අංකයට සම්බන්ධ වීමට හෝ අපගේ MyBids.lk fb පිටුවට පණිවිඩයක් යැවීමට හැකිය.</li>
                        </ul>
                        <li>MyBids.lk හි හිමිකම්</li>
                        <ul>
                            <li>සියලුම ග්‍රාහකයින් මෙහි නියම සහ කොන්දේසි කියවා සම්පූර්ණයෙන් අවබෝධ කරගෙන ඇති බවට සහතික වේ.</li>
                            <li>ජයග්‍රාහකයන්ගේ නම් කැමති ඕනෑම මාධ්‍යයක ප්‍රකාශ කිරීමේ අයිතිය MyBids.lk සතුය.</li>
                            <li>ත්‍යාග තරඟයේ අවසාන ජයග්‍රාහකයා සම්බන්ධයෙන් Dialog හෝ MyBids.lk අවසන් තීරණය අවසාන සහ සාකච්ඡා කළ නොහැකි ලෙස සලකනු ලැබේ.</li>
                            <li>MyBids.lk ග්‍රාහකයින්ට පූර්ව දැනුම් දීමකින් තොරව ත්‍යාග තරඟය හෝ එහි පිරිනැමීම් හෝ නියමයන් සහ කොන්දේසි සංශෝධනය කිරීමේ අයිතිය රඳවා තබා ගනී.</li>
                            <li>ඉහත සඳහන් නියමයන් සහ කොන්දේසිවලට එරෙහිව තරඟ කරන ඕනෑම තරඟකරුවෙකු/වෙකු නුසුදුස්සකු කිරීමට MyBids.lk හෝ Dialog වෙත පරම අයිතිය ඇත.</li>
                        </ul>
                        <li>MyBids.lk හි අරමුණ</li>
                        <ul>
                            <li>සෑම කෙනෙකුම පරිපූර්ණ ජයග්‍රාහකයෙකු බවට පත් කර සියලුම පාරිභෝගිකයින්ට සාධාරණ හා සමාන අවස්ථාවක් ලබා දීම.</li>
                        </ul>
                    </ol>
                    <p style={{textAlign: "justify"}}>තුන්වන පාර්ශ්වයේ ජංගම ජාල ක්‍රිකාකරු/ක්‍රියාකරුවන් සඳහා වන, <a href='https://dlg.dialog.lk/tc/vas'>https://dlg.dialog.lk/tc/vas</a> වෙබ් අඩවියේ තිබෙන නියමයන් සහ කොන්දේසි ඔබ විසින් කියවා මැනවින් වටහා ගෙන ඇති බවට ඔබ මෙයින් එකඟවනු ලැබේ. මෙම වෙබ් අඩවිය ඔබ විසින් භාවිත කිරීම හේතුවෙන්ම මෙම නියමයන් සහ කොන්දේසි අනුගමනය කොට ඒවායින් බැඳී සිටින බවට වන ඔබගේ එකඟතාව පිළිබිඹු වන බව සලකන්න.</p>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TutorialEnglish;