import * as React from 'react';
import { Typography, Link, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

function TutorialEnglish() {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Typography marginTop={3} variant='body1' fontWeight={'bold'}>Mybids என்றால் என்ன?</Typography>
            <Typography marginBottom={1} variant='body2'>MyBids என்பது ஒரு ஏல தளமாகும், இது உங்கள் கனவுப் பொருளை சந்தை விலையின் ஒரு பகுதியிலேயே வாங்க உதவுகிறது.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>எப்படி இது செயல்படுகிறது?</Typography>
            <Typography marginBottom={1} variant='body2'>சந்தாவில் நீங்கள் ஒரு நாளைக்கு 5 ஏல வாய்ப்புகளைப் பெறுவீர்கள். Mybids அமைப்பு ஏலம் எடுத்த பிறகு, குறைந்த மற்றும் தனித்துவமான ஏலத்தை வழங்கிய வாடிக்கையாளரை வெற்றியாளராக தேர்ந்தெடுக்கும்.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>எப்படி செயல்படுத்துவது?</Typography>
            <Typography marginBottom={1} variant='body2'>டயல் <Link href="tel:#4545*1#" underline="hover">#4545*1#</Link></Typography>
            <Typography variant='body1' fontWeight={'bold'}>செயலிழக்க செய்வது எப்படி?</Typography>
            <Typography marginBottom={1} variant='body2'>டயல் <Link href="tel:#4545*5#" underline="hover">#4545*5#</Link></Typography>
            <Typography variant='body1' fontWeight={'bold'}>எப்படி விளையாடுவது?</Typography>
            <Typography marginBottom={1} variant='body2'><Link href="tel:#4545*2#" underline="hover">#4545*2#</Link> டயல் செய்து உங்கள் ஏலத்தை வைக்கவும். அல்லது “BID your bid value” (எ.கா. BID 40) என டைப் செய்து 4545க்கு SMS செய்யவும்.</Typography>
            <Typography variant='body1' fontWeight={'bold'}>சந்தாவுக்கு எவ்வளவு செலவாகும்?</Typography>
            <Typography marginBottom={3} variant='body1'>ஒரு நாளைக்கு ரூ.6 + வரி மட்டுமே.</Typography>
            <Typography marginBottom={10}>
                <Button variant='contained' color='primary' onClick={handleClickOpen('paper')}>
                    விதிமுறைகள் மற்றும் நிபந்தனைகள்
                </Button></Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" fontWeight='bold'>Dialog MyBid சேவையின் விதிமுறைகள் மற்றும் நிபந்தனைகள் பின்வருமாறு</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <ol>
                        <li>போட்டியின் தன்மை</li>
                        <ul>
                            <li>இந்த போட்டியானது பரிசுகளை வெல்வதில் பெரும் எண்ணம் கொண்ட Dialog வாடிக்கையாளர்களை இலக்காகக் கொண்டது.</li>
                            <li>இந்த போட்டி சந்தாதாரர்களுக்கு ஏல அனுபவத்தில் பங்குபெறவும் மதிப்புமிக்க பரிசுகளை வெல்லவும் வாய்ப்பளிக்கும்.</li>
                            <li>குறைந்த மற்றும் தனித்துவமான ஏலத்தின் அடிப்படையில் வெற்றியாளர் தேர்ந்தெடுக்கப்படுவார்.(போட்டியின் பொதுவான நடத்தை)</li>
                            <li>போட்டியில் பங்கேற்பதன் மூலம், ஒவ்வொரு சந்தாதாரரும்/வாடிக்கையாளரும் இங்குள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளுக்கு நிபந்தனையின்றி உடன்படுவார்கள் மற்றும் அனைத்து விஷயங்களுக்கும் Dialog Axita PLC (MyBids.lk) இன் முடிவுகளுக்குக் கட்டுப்படுவார்கள்.</li>
                            <li>பரிசுப் போட்டியில் பங்கேற்பதன் மூலம் சந்தாதாரர் விளையாட்டின் அனைத்து விதிமுறைகள் மற்றும் நிபந்தனைகள் மற்றும் Dialog அல்லது MyBids.lk ஆல் பெறப்படும் முடிவுகளை இறுதியாக ஏற்றுக்கொள்வதற்கு ஒப்புக்கொள்கிறார், மேலும் பரிசுப் போட்டியின் வாழ்வாதாரத்தின் போதும் அதற்குப் பிறகும் அது சர்ச்சைக்கு உள்ளாகாது.</li>
                        </ul>
                        <li>நுழைவு தேவைகள்</li>
                        <ul>
                            <li>வாடிக்கையாளர்கள் விருப்பமான ஊடகம் மூலம் சேவையில் பதிவு செய்யலாம். (இணையம், USSD, SMS)</li>
                        </ul>
                        <li>போட்டியின் நிபந்தனைகள்</li>
                        <ul>
                            <li>ஒருவர் ஒரு முறை மட்டுமே வெற்றி பெற முடியும். முந்தைய வெற்றி எண்களின் NIC வைத்திருப்பவர்களுக்கு அடுத்த பரிசு வழங்கப்படாது.</li>
                            <li>போட்டியாளர்கள் ஒரே NIC எண்ணில் பதிவுசெய்யப்பட்ட பல msisdn/மொபைல் எண்கள் மூலம் போட்டியிடலாம், ஆனால் அவர்/அவள் போட்டியில் வெற்றி பெற்றவுடன் அடுத்த முறை அதே NIC வைத்திருப்பவருக்கு பரிசு வழங்கப்படாது, இருப்பினும் அவர் / அவள் குறைந்த ஏலதாரர்.</li>
                            <li>அதே உள்ளூர் முகவரிக்கு பதிவுசெய்யப்பட்ட MSISDN's மூலம் ஒருவர் மட்டுமே பரிசை வெல்ல முடியும்.</li>
                            <li>வெற்றிபெறும் மொபைல் எண்ணின் அசல் உரிமையாளர், செல்லுபடியாகும் NIC அல்லது அடையாளச் சான்றுடன் பரிசை ஏற்க வர வேண்டும். இல்லையெனில் வெற்றி நிராகரிக்கப்படும் மற்றும் அவர்/அவள் அடுத்த ஏல சுற்றுகளுக்கு தகுதி நீக்கம் செய்யப்படுவார்.</li>
                        </ul>
                        <li>தகுதி நீக்கத்திற்கான காரணங்கள்</li>
                        <ul>
                            <li>Dialog அல்லது MyBids.lk இன் நிர்வாகம் எந்தவொரு நபரும் மற்ற போட்டியாளர்களை ஏமாற்றி ஏமாற்ற முயற்சிக்கிறார் என்பதைப் புரிந்துகொள்கிறார்கள், போட்டி - அவர்/அவள் போட்டிக்குத் தகுதியற்றவர்.</li>
                        </ul>
                        <li>வாடிக்கையாளர் விசாரணைகள் & புகார்கள்</li>
                        <ul>
                            <li>பரிசுப் போட்டி தொடர்பான பொதுவான புகார்கள் மற்றும் விசாரணைகளுக்கு அனைத்து சந்தாதாரர்களும் Dialog வாடிக்கையாளர் சேவைக்கான ஹாட்லைனைத் தொடர்பு கொள்ளலாம் அல்லது எங்கள் MyBids.lk fb பக்கத்தில் ஒரு செய்தியை அனுப்பலாம்.</li>
                        </ul>
                        <li>MyBids.lk இன் உரிமைகள்</li>
                        <ul>
                            <li>அனைத்து சந்தாதாரர்களும் இங்குள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளைப் படித்து முழுமையாகப் புரிந்து கொண்டதாக உத்தரவாதம் அளிக்கிறார்கள்.</li>
                            <li>MyBids.lk வெற்றியாளர்களின் பெயர்களை விருப்பமான எந்த ஊடகத்திலும் வெளியிடும் உரிமையை கொண்டுள்ளது.</li>
                            <li>பரிசுப் போட்டியின் இறுதி வெற்றியாளரைப் பற்றிய Dialog அல்லது MyBids.lk இறுதி முடிவு இறுதியானது மற்றும் பேச்சுவார்த்தைக்குட்பட்டது அல்ல.</li>
                            <li>சந்தாதாரர்களுக்கு முன் அறிவிப்பு இல்லாமல் பரிசுப் போட்டி அல்லது அதன் சலுகைகள் அல்லது விதிமுறைகள் மற்றும் நிபந்தனைகளைத் திருத்துவதற்கான உரிமையை MyBids.lk கொண்டுள்ளது.</li>
                            <li>மேலே குறிப்பிட்டுள்ள விதிமுறைகள் மற்றும் நிபந்தனைகளுக்கு எதிராக போட்டியிடும் எந்தவொரு போட்டியாளரையும்/களை தகுதி நீக்கம் செய்ய MyBids.lk அல்லது Dialog க்கு முழு உரிமை உண்டு.</li>
                        </ul>
                        <li>MyBids.lk இன் நோக்கம்</li>
                        <ul>
                            <li>ஒவ்வொருவரையும் சரியான வெற்றியாளராக மாற்றி, அனைத்து வாடிக்கையாளர்களுக்கும் நியாயமான மற்றும் சமமான வாய்ப்பை வழங்குங்கள்.</li>
                        </ul>
                    </ol>
                    <p style={{textAlign: "justify"}}><a href='https://dlg.dialog.lk/tc/vas'>https://dlg.dialog.lk/tc/vas</a> இணையத்தளத்தில் மூன்றாம் தரப்பு கையடக்கத்தொலைபேசி வலைப்பின்னல் இயக்குனர்/களின் நியதிகள் மற்றும் நிபந்தனைகளை நீங்கள் வாசித்து முழுமையாக புரிந்து கொண்டீர்கள் என்பதை இத்தால் ஒப்புக்கொள்கிறீர்கள். இந்த இணையத்தளத்தை நீங்கள் பயன்படுத்துவதானது, அத்தகைய நியதிகள் மற்றும் நிபந்தனைகளைப் பின்பற்றுவதற்கும் மற்றும் அவற்றுக்கு கட்டுப்படுவதற்குமான உங்கள் உடன்பாட்டை உருவாக்குகிறது.</p>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TutorialEnglish;