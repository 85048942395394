import React, { useState, useEffect } from 'react';
import { config } from '../../Constants'
import './CardSlider.css';
import LiveCard from '../live-card/LiveCard';
import { Grid, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function CardSlider() {
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        var baseUrl = config.url.API_URL
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ biddingState: 'live' })
        };
        fetch(baseUrl+'/mybids/api/v1/products/getProducts', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                setPostData(data.data);

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    return (
        postData ? <div id='main-slider-container'>
            {/* <MdOutlineChevronLeft className='slider-icon left' size={40} onClick={slideLeft}></MdOutlineChevronLeft> */}
            <div id='slider'>
                {postData.map((slide, index) => (
                    <Grid item key={index} className='card-grid'>
                        <LiveCard slide={slide} />
                    </Grid>
                ))}
            </div>
            {/* <MdOutlineChevronRight className='slider-icon right' size={40} onClick={slideRight}></MdOutlineChevronRight> */}
        </div> :
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={90}/>
            </Box>

    );
}

LiveCard.propTypes = {
    loading: PropTypes.bool,
};

export default CardSlider;