const prod = {
  url: {
    API_URL: "http://mybids.lk",
    API_URL_USERS: "http://mybids.lk",
    IMG_URL: "http://mybids.lk",
  },
};

const dev = {
  url: {
    API_URL: "http://mybids.lk",
    IMG_URL: "http://mybids.lk",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
