import * as React from 'react';
import { Typography, Link, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

function TutorialEnglish() {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Typography marginTop={3} variant='h6' fontWeight={600}>What’s Mybids?</Typography>
            <Typography marginBottom={1} variant='body2'>MyBids is a bidding platform enable you to buy your dream product at a fraction of market price.</Typography>
            <Typography variant='h6' fontWeight={600}>How it works?</Typography>
            <Typography marginBottom={1} variant='body2'>On subscription you will receive 5 biding chances per day. After bidding over Mybids system will select the customer who has placed LOWEST and UNIQUE bid as the winner.</Typography>
            <Typography variant='h6' fontWeight={600}>How to activate?</Typography>
            <Typography marginBottom={1} variant='body2'>Dial <Link href="tel:#4545*1#" underline="hover">#4545*1#</Link></Typography>
            <Typography variant='h6' fontWeight={600}>How to deactivate?</Typography>
            <Typography marginBottom={1} variant='body2'>Dial <Link href="tel:#4545*5#" underline="hover">#4545*5#</Link></Typography>
            <Typography variant='h6' fontWeight={600}>How to play?</Typography>
            <Typography marginBottom={1} variant='body2'>Dial <Link href="tel:#4545*2#" underline="hover">#4545*2#</Link> and place your bid. Or type “BID your bid value” (e.g. BID 40) and SMS to 4545.</Typography>
            <Typography variant='h6' fontWeight={600}>How much it’ll cost for subscription?</Typography>
            <Typography marginBottom={3} variant='body1'>Just Rs. 6 + tax per day.</Typography>
            <Typography marginBottom={10}>
                <Button variant='contained' color='primary' onClick={handleClickOpen('paper')}>
                    Terms and Conditions
                </Button></Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">The following are the terms and conditions of the Dialog MyBid service</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <ol>
                        <li>Nature of competition</li>
                        <ul>
                            <li>This competition is aimed for the Dialog customers who has a great intention toward winning gifts.</li>
                            <li>This competition shall provide subscribers an opportunity to participate in a bidding experience and win valueble prizes.</li>
                            <li>The winner will be selected based on lowest and unique bid.(common behavior of the competition)</li>
                            <li>By participating in the Competition, each Subscriber/customer shall unconditionally agree to the Terms and Conditions here to and shall accede to be bound by the decision/s of Dialog Axita PLC (MyBids.lk) pertaining to all matters.</li>
                            <li>Subscriber by way of participating in the Prize Competition agrees to accept all the terms and conditions of the game and decisions gain by Dialog or MyBids.lk as final and the same shall not be disputed during the subsistence of the Prize Competition and thereafter.</li>
                        </ul>
                        <li>Entry requirements</li>
                        <ul>
                            <li>Customers can registered to the service through preferable medium. (WEB,USSD,SMS)</li>
                        </ul>
                        <li>Conditions of the competition</li>
                        <ul>
                            <li>One person can only win once. The next prize will not be given to the NIC holders of previous winning numbers.</li>
                            <li>The competitors can compete through multiple msisdn's /mobile numbers registered to the same NIC number but once he /she win the competition next time the prize will not be issued for the same NIC holder although he / she is the lowest bidder.</li>
                            <li>Only one person can win the prize through the MSISDN'S registered for the same local address.</li>
                            <li>The original owner of the winning mobile number should come to accept the prize with the valied NIC or proof of identification . Otherwise the winning will be rejected and he/she will be disqualified for next bidding rounds.</li>
                        </ul>
                        <li>Reasons for disqualification</li>
                        <ul>
                            <li>The management of the Dialog or MyBids.lk come to understand any person is trying to fraud and cheat on other competitors, competition - he/she will be disqualified for the competition.</li>
                        </ul>
                        <li>Customer Inquiries & Complaints</li>
                        <ul>
                            <li>For General complaints and inquiries in relation to the Prize Competition all Subscribers can contact Dialog customer care hotline or drop a message in our MyBids.lk fb page</li>
                        </ul>
                        <li>Rights of MyBids.lk</li>
                        <ul>
                            <li>All Subscribers warrant that they have read and fully understood the Terms and Conditions here.</li>
                            <li>MyBids.lk reserves the right to publish the names of the winners on any preferred media.</li>
                            <li>Dialog or MyBids.lk final decision, in regarding the final winner of the Prize Competition will be deemed final and non-negotiable.</li>
                            <li>MyBids.lkreserves the right to amend the Prize Competition or its offers or Terms and Conditions without prior notice to the Subscriber/s.</li>
                            <li>MyBids.lk or Dialog shall have the absolute right to disqualify any competitor/s who compete against the above mentioned terms and conditions.</li>
                        </ul>
                        <li>Objective of MyBids.lk</li>
                        <ul>
                            <li>Make every one a perfect winner and give a fair and equal opportunity for all customers.</li>
                        </ul>
                    </ol>
                    <p style={{textAlign: "justify"}}>You hereby agree that You have read and fully understood the terms and conditions of the third party mobile network operator/s at <a href='https://dlg.dialog.lk/tc/vas'>https://dlg.dialog.lk/tc/vas</a>. Your use of this website constitutes Your agreement to follow and be bound by such terms and conditions.</p>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TutorialEnglish;